@import 'tailwindcss';
@import './components/copied-tooltip.css' layer(components);
@import './components/icons.css' layer(components);
@import './components/kofi-button.css' layer(components);
@import './components/lightbox.css' layer(components);
@import './components/prose-overrides.css' layer(components);
@import './components/theme-toggle.css' layer(components);
@import './components/toc.css' layer(components);

@config '../../tailwind.config.ts';

@layer base {
  @font-face {
    font-family: 'Inter Variable';
    font-style: normal;
    font-display: block;
    font-weight: 100 900;
    src:
      local('Inter Variable'),
      url(data-url:npm:@fontsource-variable/inter/files/inter-latin-wght-normal.woff2)
        format('woff2-variations');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6,
      U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC,
      U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  html, :host {
    @apply bg-white dark:bg-gray-900;
    @apply text-black dark:text-white;
    @apply antialiased scroll-smooth;
  }

  .link {
    @apply underline;
    @apply font-medium;
  }

  .link-hover {
    @apply no-underline;
    @apply hover:underline;
  }

  .fluid {
    /* Needed to escape prose overrides on imgs. */
    @apply max-w-full!;
  }
}

@utility title {
  @apply text-4xl sm:text-5xl font-light;
}
